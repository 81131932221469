<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import ClienteService from "@/services/ClienteService";
const itemService = new ClienteService();
import moment from "moment";
import Swal from "sweetalert2";

/*import MovementService from "@/services/MovementService";



const itemService = new MovementService();*/

/**
 * Invoice-list component
 */
const nameSeccion = 'Cliente';
const namePlural = nameSeccion+'s';
const routeParentName = 'clientes';
export default {
  page: {
    title: "Listado de "+ namePlural,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: namePlural,
      items: [
        {
          text: namePlural,
        },
        {
          text: "Listado de "+ namePlural,
          active: true,
        },
      ],
      filterBar:{
        currency: '',
        type: '',
        from: null,
        to: null
      },
      invoicelist: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      info_page:{
        routeParentName: null,
        nameSeccion: null,
      },
      fields: [
             
        {
          key: "full_name",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "email",
          label: "Correo Electrónico"
        },
        {
          key: "phone",
          label: "Teléfono"
        },
        
        {
          key: "action",
          label: "Acciones",
          sortable: true,
        }
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.invoicelist.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created(){

    this.initPage();
    this.chargeItems();
  },

  methods: {
    /**
     * Search the table data with search input
     */
    initPage(){
      this.info_page.routeParentName = routeParentName;
      this.info_page.nameSeccion = nameSeccion;
    },
    chargeItems(){

        itemService.getList().then(response=>{
          this.invoicelist = response.data.object_data;
        });
        
    },
    
    applyFilter(){
      let params = {...this.filterBar};
      if(params.from!==null){
        params.from = moment(params.from, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
      if(params.to!==null){        
        params.to = moment(params.to, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
     
       itemService.getList(params).then(response=>{
          this.invoicelist = response.data.object_data;
        });

    },
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
    
    deleteElement(id){
      itemService.delete(id).then(()=>{
         this.successForm();
         this.chargeItems();
      })
    },
    successForm(){
      Swal.fire("¡Operación exitosa!", "El registro fué eliminado", "success");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-4">
        <div>
          <button type="button" class="btn btn-success mb-3"  @click="routeGo(info_page.routeParentName+'/agregar')">
            <i class="mdi mdi-plus me-1"></i> Agregar {{info_page.nameSeccion}}
          </button>
        </div>
      </div>
      <div class="col-md-8">
      
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center fw-normal">
            Mostrar&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
          <label class="d-inline-flex align-items-center fw-normal">
            Buscar:
            <b-form-input
              v-model="filter"
              type="search"
              class="form-control form-control-sm ms-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table
        table-class="table table-centered datatable table-card-list"
        thead-tr-class="bg-transparent"
        :items="invoicelist"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
      

        <template v-slot:cell(name)="data">
          <a @click="routeGo(info_page.routeParentName+'/editar/'+data.item.id)" class="text-body">{{ data.item.name }}</a>
        </template>
      
        <template v-slot:cell(action)="data">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a
                href="javascript:void(0);"
                class="px-2 text-primary"
                v-b-tooltip.hover
                title="Editar"
                @click="routeGo(info_page.routeParentName+'/editar/'+data.item.id)"
              >
                <i class="uil uil-pen font-size-18"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                href="javascript:void(0);"
                class="px-2 text-danger"
                v-b-tooltip.hover
                title="Eliminar"
                 @click="deleteElement(data.item.id)"
              >
                <i class="uil uil-trash-alt font-size-18"></i>
              </a>
            </li>
          </ul>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>
